import { dayToISOFormat } from "../../utils/datesUtils";

const COURT_START_HOUR = 7;
const COURT_START_MINUTE = 0;
const COURT_END_HOUR = 24;
const COURT_END_MINUTE = 0;

const calculateLength = (startHour, startMinute, endHour, endMinute) => {
    const endHourCorrectedForMidnight = endHour === 0 ? 24 : endHour;

    const bookingLengthInMinutes = endHourCorrectedForMidnight * 60 + endMinute - (startHour * 60 + startMinute);
    const numberOf30MinutesBlocks = bookingLengthInMinutes / 30;
    const blockLengthInPixels = 12;

    return numberOf30MinutesBlocks * blockLengthInPixels;
};

const noBookingForTheDay = (allBookingsForTheDay) => !allBookingsForTheDay || allBookingsForTheDay.length === 0;

const generateAllDayBooking = () => [
    {
        start_hour: 7,
        start_minute: 0,
        end_hour: 0,
        end_minute: 0,
        length: calculateLength(7, 0, 24, 0),
    },
];

const sortBookingsByStartHour = (allBookingsForTheDay) =>
    allBookingsForTheDay.sort(function (a, b) {
        return a.start_hour - b.start_hour;
    });

const blockTimeBeforeFirstBooking = (allBookingsForTheDay) => {
    if (allBookingsForTheDay[0].start_hour > COURT_START_HOUR) {
        return {
            start_hour: COURT_START_HOUR,
            start_minute: COURT_START_MINUTE,
            end_hour: allBookingsForTheDay[0].start_hour,
            end_minute: allBookingsForTheDay[0].start_minute,
            length: calculateLength(
                COURT_START_HOUR,
                COURT_START_MINUTE,
                allBookingsForTheDay[0].start_hour,
                allBookingsForTheDay[0].start_minute
            ),
        };
    }

    return null;
};

const blockTimeAfterLastBooking = (allBookingsForTheDay) => {
    const lastBookingForTheDay = allBookingsForTheDay[allBookingsForTheDay.length - 1];

    if (lastBookingForTheDay.start_hour < COURT_END_HOUR && lastBookingForTheDay.end_hour !== 0) {
        return {
            start_hour: lastBookingForTheDay.end_hour,
            start_minute: lastBookingForTheDay.end_minute,
            end_hour: 0,
            end_minute: COURT_END_MINUTE,
            length: calculateLength(
                lastBookingForTheDay.end_hour,
                lastBookingForTheDay.end_minute,
                COURT_END_HOUR,
                COURT_END_MINUTE
            ),
        };
    }

    return null;
};

const getNextBooking = (allBookingsForTheDay, i) => {
    if (i + 1 < allBookingsForTheDay.length) return allBookingsForTheDay[i + 1];
    return null;
};

const freeSlotsBetweenBookings = (booking1, booking2) => {
    if (!booking1 || !booking2) return false;

    return (
        booking1.end_hour < booking2.start_hour ||
        (booking1.end_hour === booking2.start_hour && booking1.end_minute < booking2.start_minute)
    );
};

export const generateBookingsForTheDay = (datesMap, day) => {
    const allBookingsForTheDay = datesMap[dayToISOFormat(day)];

    if (noBookingForTheDay(allBookingsForTheDay)) return generateAllDayBooking();

    sortBookingsByStartHour(allBookingsForTheDay);

    const finalBookingsView = [];

    const firstBooking = blockTimeBeforeFirstBooking(allBookingsForTheDay);
    if (firstBooking) finalBookingsView.push(firstBooking);

    let currentBookingStartHour = null;
    let currentBookingStartMinute = null;

    for (let i = 0; i < allBookingsForTheDay.length; i++) {
        const booking = allBookingsForTheDay[i];
        const nextBooking = getNextBooking(allBookingsForTheDay, i);

        if (!currentBookingStartHour) currentBookingStartHour = booking.start_hour;
        if (!currentBookingStartMinute) currentBookingStartMinute = booking.start_minute;

        if (nextBooking && !freeSlotsBetweenBookings(booking, nextBooking)) {
            // if we have bookings back to back, we merge them into a bigger booking
            continue;
        }

        finalBookingsView.push({
            bookedSlot: true,
            start_hour: currentBookingStartHour,
            start_minute: currentBookingStartMinute,
            end_hour: booking.end_hour,
            end_minute: booking.end_minute,
            length: calculateLength(
                currentBookingStartHour,
                currentBookingStartMinute,
                booking.end_hour,
                booking.end_minute
            ),
        });

        currentBookingStartHour = null;
        currentBookingStartMinute = null;

        if (freeSlotsBetweenBookings(booking, nextBooking)) {
            finalBookingsView.push({
                start_hour: booking.end_hour,
                start_minute: booking.end_minute,
                end_hour: nextBooking.start_hour,
                end_minute: nextBooking.start_minute,
                length: calculateLength(
                    booking.end_hour,
                    booking.end_minute,
                    nextBooking.start_hour,
                    nextBooking.start_minute
                ),
            });
        }
    }

    const lastBooking = blockTimeAfterLastBooking(allBookingsForTheDay);
    if (lastBooking) finalBookingsView.push(lastBooking);

    return finalBookingsView;
};
