import React, { useState, useEffect, useCallback, useMemo } from "react";
import { fetchBookings } from "../../api/fetchBookings";
import "./main.css";
import { formatDate, getStartOfWeek, getWeekDays, formatTime, getNextSevenDays } from "../../utils/datesUtils";
import CourtTags from "../Court/CourtTags";
import { generateBookingsForTheDay } from "./bookingViewGenerator";

const BookingManager = ({ courtId, turfId, turfTags }) => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [bookings, setBookings] = useState([]);
    const [showPreviousWeekArrow, setShowPreviousWeekArrow] = useState(false);

    const startOfCurrentWeek = getStartOfWeek(new Date());
    const startOfWeek = useMemo(() => getStartOfWeek(new Date(currentDate)), [currentDate]);
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(endOfWeek.getDate() + 6);

    const weekDays = getWeekDays(startOfWeek);

    const dates = getNextSevenDays(startOfWeek);

    const fetchBookingsForCourt = useCallback(async () => {
        if (courtId && turfId) {
            const bookingsForCourt = await fetchBookings(courtId, turfId, ["accepted"], startOfWeek);
            setBookings(bookingsForCourt);
        }
    }, [courtId, turfId, startOfWeek]);

    useEffect(() => {
        fetchBookingsForCourt();
    }, [courtId, turfId, fetchBookingsForCourt]);

    bookings?.sort((a, b) => {
        if (a?.start_hour < b?.start_hour) return -1;
        if (a?.start_hour === b?.start_hour && a?.start_minute <= b?.start_minute) return -1;

        return 1;
    });

    const dateToTimesMap = bookings?.reduce((map, booking) => {
        const formattedDate = booking.date;

        if (dates.some((date) => date.date === formattedDate)) {
            if (!map[formattedDate]) {
                map[formattedDate] = [];
            }
            map[formattedDate].push({ ...booking }); // Flatten the array
        }
        return map;
    }, {});

    const handlePreviousWeek = () => {
        const newDate = new Date(currentDate);
        newDate.setDate(newDate.getDate() - 7);

        if (newDate < startOfCurrentWeek) {
            setShowPreviousWeekArrow(false);
            return;
        }

        setCurrentDate(newDate);
        setShowPreviousWeekArrow(true);
    };

    const handleNextWeek = () => {
        const newDate = new Date(currentDate);
        newDate.setDate(newDate.getDate() + 7);
        setCurrentDate(newDate);
        setShowPreviousWeekArrow(true);
    };

    return (
        <div className="bookingManager-container">
            <div className="controls">
                <div className="bookingManager-title">Zakazivanje termina</div>
                <div className="bookingManager-header">
                    {showPreviousWeekArrow && (
                        <button className="arrowButton" onClick={handlePreviousWeek}>
                            &lt;
                        </button>
                    )}
                    <div className="dateRange">
                        {formatDate(startOfWeek)} - {formatDate(endOfWeek)}
                    </div>
                    <button className="arrowButton" onClick={handleNextWeek}>
                        &gt;
                    </button>
                </div>
                <div className="bookingManager-tags">
                    <CourtTags turfTags={turfTags} />
                </div>
            </div>
            <div className="weekDays">
                {weekDays.map((day, index) => (
                    <div key={index} className="day">
                        <div className="dayName">{day.toLocaleDateString("sr-Latn-RS", { weekday: "long" })}</div>
                        <div className="bookings">
                            <div className="bookingsForTheDay">
                                {generateBookingsForTheDay(dateToTimesMap, day).map((time, idx) => (
                                    <div
                                        key={idx}
                                        className="bookedTime"
                                        style={{
                                            height: `${time.length}px`,
                                            backgroundColor: time.bookedSlot ? "#d3d4d9" : "lightgreen",
                                            opacity: time.bookedSlot ? "1" : "0",
                                        }}
                                    >
                                        {formatTime(time.start_hour, time.start_minute, time.end_hour, time.end_minute)}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BookingManager;
