import { db } from "../firebaseConfig";
import { collection, doc, addDoc, updateDoc, writeBatch } from "firebase/firestore";
import { formatTimePart } from "../utils/datesUtils";
import { fetchBookings } from "../api/fetchBookings";

export const useBooking = ({ user, courtId, turfId, bookingStates }) => {
    const addBooking = async (date, startTime, endTime, customUser, autoAccept) => {
        if (!user) {
            return { success: false, errorMessage: "Korisnik nije ulogovan!" };
        }

        if (!user.emailVerified) {
            return { success: false, errorMessage: "Vaš email nije verifikovan!" };
        }

        const reservationStatus = autoAccept ? "accepted" : "requested";

        const bookings = await fetchBookings(courtId, turfId, bookingStates);

        const validationResult = validateBooking(date, startTime, endTime, bookings);

        if (!validationResult || !validationResult?.success) {
            return { success: false, errorMessage: validationResult?.errorMessage };
        }

        try {
            const start_hour = startTime.hour;
            const start_minute = startTime.minute;
            const end_hour = endTime.hour;
            const end_minute = endTime.minute;
            const reservation_requested_by = customUser ? customUser : user.email;
            const reservation_status = reservationStatus;
            const createdAt = new Date(date).getTime();
            const timestamp = Date.now();

            await addDoc(collection(db, "bookings"), {
                court_id: courtId,
                turf_id: turfId,
                date,
                start_hour,
                start_minute,
                end_hour,
                end_minute,
                reservation_requested_by,
                reservation_status,
                created_at: createdAt,
                timestamp: timestamp,
            });

            return { success: true };
        } catch (e) {
            return { success: false, errorMessage: "Serverska greška!" };
        }
    };

    const getTimeFromParts = (date, hour, minute) => {
        return new Date(`${date}T${formatTimePart(hour)}:${formatTimePart(minute)}`).getTime();
    };

    const validateBooking = (date, startTime, endTime, bookings) => {
        const result = { success: false, errorMessage: "Serverska greška!" };

        const newStart = getTimeFromParts(date, startTime.hour, startTime.minute);
        const newEnd = getTimeFromParts(date, endTime.hour === 0 ? 24 : endTime.hour, endTime.minute);

        if (newStart >= newEnd && endTime.hour !== 0) {
            result.errorMessage = "Početno vreme termina mora biti veće od krajnjeg.";
            return result;
        }

        if (startTime.hour === endTime.hour) {
            result.errorMessage = "Trajanje termina mora biti najmanje sat vremena.";
            return result;
        }

        const acceptedBookings = bookings.filter((t) => t.reservation_status === "accepted");

        for (const booking of acceptedBookings) {
            const bookingStart = getTimeFromParts(booking.date, booking.start_hour, booking.start_minute);
            const bookingEnd = getTimeFromParts(
                booking.date,
                booking.end_hour === 0 ? 24 : booking.end_hour,
                booking.end_minute
            );

            if (
                booking.date === date &&
                ((newStart >= bookingStart && newStart < bookingEnd) || (newEnd > bookingStart && newEnd <= bookingEnd))
            ) {
                result.errorMessage = "Izabrani termin se preklapa sa već rezervisanim terminom!";
                return result;
            }
        }

        result.success = true;
        result.errorMessage = "";

        return result;
    };

    const areBookingsOverlapping = (booking1, booking2) => {
        if (new Date(booking1.date).toDateString() !== new Date(booking2.date).toDateString()) return false;

        // if the first booking starts after the end of the second booking, they are not overlapping
        if (
            getTimeFromParts(booking1.date, booking1.start_hour, booking1.start_minute) >=
            getTimeFromParts(booking2.date, booking2.end_hour, booking2.end_minute)
        )
            return false;

        // if the first booking ends before the second booking, they are not overlapping
        if (
            getTimeFromParts(booking1.date, booking1.end_hour, booking1.end_minute) <=
            getTimeFromParts(booking2.date, booking2.start_hour, booking2.start_minute)
        )
            return false;

        return true;
    };

    const isBookingOverlappingWithAnotherFinalizedBooking = (booking, allBookings) => {
        const finalizedBookings = allBookings.filter((t) => t.reservation_status === "accepted");
        return finalizedBookings.some((t) => areBookingsOverlapping(t, booking));
    };

    const acceptBooking = async (bookingId) => {
        const allBookings = await fetchBookings(courtId, turfId, bookingStates);

        const currentBooking = allBookings.find((t) => t.id === bookingId);

        if (!currentBooking) return { success: false, errorMessage: "Serverska greška!" };

        if (isBookingOverlappingWithAnotherFinalizedBooking(currentBooking, allBookings))
            return { success: false, errorMessage: "Izabrani termin se preklapa sa već rezervisanim terminom!" };

        const bookingRef = doc(db, "bookings", bookingId);

        await updateDoc(bookingRef, {
            reservation_status: "accepted",
        });

        return { success: true, errorMessage: "" };
    };

    const rejectBooking = async (bookingId) => {
        const allBookings = await fetchBookings(courtId, turfId, bookingStates);

        const currentBooking = allBookings.find((t) => t.id === bookingId);

        if (!currentBooking) return { success: false, errorMessage: "Serverska greška!" };

        if (currentBooking.isRecurring) {
            await handleRecurringBookingReject(currentBooking);
        } else {
            await handleStandardBookingReject(bookingId);
        }

        return { success: true, errorMessage: "" };
    };

    const cancelBooking = async (bookingId) => {
        const bookingRef = doc(db, "bookings", bookingId);

        await updateDoc(bookingRef, {
            reservation_status: "cancelled",
        });

        return { success: true, errorMessage: "" };
    };

    const handleRecurringBookingReject = async (booking) => {
        await addDoc(collection(db, "bookings"), {
            court_id: booking.court_id,
            turf_id: booking.turf_id,
            date: booking.date,
            start_hour: booking.start_hour,
            start_minute: booking.start_minute,
            end_hour: booking.end_hour,
            end_minute: booking.end_minute,
            reservation_requested_by: booking.reservation_requested_by,
            reservation_status: "rejected",
            created_at: new Date(booking.date).getTime(),
            timestamp: Date.now(),
            wasRecurring: true,
        });
    };

    const handleStandardBookingReject = async (bookingId) => {
        const bookingRef = doc(db, "bookings", bookingId);
        await updateDoc(bookingRef, {
            reservation_status: "rejected",
        });
    };

    const splitBooking = async (bookingId, startTime, endTime) => {
        const allBookings = await fetchBookings(courtId, turfId, bookingStates);

        const currentBooking = allBookings.find((t) => t.id === bookingId);

        if (!currentBooking) return { success: false, errorMessage: "Serverska greška!" };

        const currentBookingEndHour = currentBooking.end_hour === 0 ? 24 : currentBooking.end_hour;
        const newBookingEndHour = endTime.hour === 0 ? 24 : endTime.hour;

        if (
            startTime.hour > newBookingEndHour ||
            (startTime.hour === newBookingEndHour && startTime.minute >= endTime.minute)
        ) {
            return { success: false, errorMessage: "Početno vreme mora biti veće od krajnjeg!" };
        }

        if (
            startTime.hour < currentBooking.start_hour ||
            startTime.hour > currentBookingEndHour ||
            newBookingEndHour < currentBooking.start_hour ||
            newBookingEndHour > currentBookingEndHour ||
            (newBookingEndHour === currentBookingEndHour && endTime.minute > currentBooking.end_minute)
        ) {
            return { success: false, errorMessage: "Zadato vreme nije unutar trenutne rezervacije!" };
        }

        const createdAt = new Date(currentBooking.date).getTime();
        const timestamp = Date.now();

        const splitBatchWrite = writeBatch(db);

        if (currentBooking.isRecurring) {
            const recurringBooking = doc(collection(db, "bookings"));
            splitBatchWrite.set(recurringBooking, {
                court_id: currentBooking.court_id,
                turf_id: currentBooking.turf_id,
                date: currentBooking.date,
                start_hour: currentBooking.start_hour,
                start_minute: currentBooking.start_minute,
                end_hour: currentBooking.end_hour,
                end_minute: currentBooking.end_minute,
                reservation_requested_by: currentBooking.reservation_requested_by,
                reservation_status: "split",
                created_at: new Date(currentBooking.date).getTime(),
                timestamp: Date.now(),
                wasRecurring: true,
            });
        } else {
            const bookingRef = doc(db, "bookings", bookingId);
            splitBatchWrite.update(bookingRef, {
                reservation_status: "split",
            });
        }

        if (
            startTime.hour === currentBooking.start_hour &&
            startTime.minute === currentBooking.start_minute &&
            endTime.hour === currentBooking.end_hour &&
            endTime.minute === currentBooking.end_minute
        ) {
            // if the free booking completely overlaps the current booking, we don't need to do anything
            await splitBatchWrite.commit();
            return { success: true, errorMessage: "" };
        }

        if (startTime.hour === currentBooking.start_hour && startTime.minute === currentBooking.start_minute) {
            // the free booking is on the start of the current one, we only need to add one booking to cover the remaining time
            const booking = doc(collection(db, "bookings"));
            splitBatchWrite.set(booking, {
                court_id: currentBooking.court_id,
                turf_id: currentBooking.turf_id,
                date: currentBooking.date,
                start_hour: endTime.hour,
                start_minute: endTime.minute,
                end_hour: currentBooking.end_hour,
                end_minute: currentBooking.end_minute,
                reservation_requested_by: currentBooking.reservation_requested_by,
                reservation_status: currentBooking.reservation_status,
                created_at: createdAt,
                timestamp: timestamp,
                parent_booking: bookingId,
            });
        } else if (endTime.hour === currentBooking.end_hour && endTime.minute === currentBooking.end_minute) {
            // the free booking is on the end of the current one, we only need to add one booking to cover the remaining time
            const booking = doc(collection(db, "bookings"));
            splitBatchWrite.set(booking, {
                court_id: currentBooking.court_id,
                turf_id: currentBooking.turf_id,
                date: currentBooking.date,
                start_hour: currentBooking.start_hour,
                start_minute: currentBooking.start_minute,
                end_hour: startTime.hour,
                end_minute: startTime.minute,
                reservation_requested_by: currentBooking.reservation_requested_by,
                reservation_status: currentBooking.reservation_status,
                created_at: createdAt,
                timestamp: timestamp,
                parent_booking: bookingId,
            });
        } else {
            const firstBooking = doc(collection(db, "bookings"));
            const secondBooking = doc(collection(db, "bookings"));

            splitBatchWrite.set(firstBooking, {
                court_id: currentBooking.court_id,
                turf_id: currentBooking.turf_id,
                date: currentBooking.date,
                start_hour: currentBooking.start_hour,
                start_minute: currentBooking.start_minute,
                end_hour: startTime.hour,
                end_minute: startTime.minute,
                reservation_requested_by: currentBooking.reservation_requested_by,
                reservation_status: currentBooking.reservation_status,
                created_at: createdAt,
                timestamp: timestamp,
                parent_booking: bookingId,
            });

            splitBatchWrite.set(secondBooking, {
                court_id: currentBooking.court_id,
                turf_id: currentBooking.turf_id,
                date: currentBooking.date,
                start_hour: endTime.hour,
                start_minute: endTime.minute,
                end_hour: currentBooking.end_hour,
                end_minute: currentBooking.end_minute,
                reservation_requested_by: currentBooking.reservation_requested_by,
                reservation_status: currentBooking.reservation_status,
                created_at: createdAt,
                timestamp: timestamp,
                parent_booking: bookingId,
            });
        }

        await splitBatchWrite.commit();

        return { success: true, errorMessage: "" };
    };

    return {
        addBooking,
        acceptBooking,
        rejectBooking,
        cancelBooking,
        splitBooking,
    };
};
